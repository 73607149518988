import * as React from "react";
import { PageProps } from "gatsby";
import DefaultTemplate from "../templates/default-template";
import { aboutUsSection, contactSection, staffSection, staffSectionML, staffSectionPM, storySection, techStackSection, testimontialSection } from "../data/data";
import "./about-us.scss";
import SectionHeader from "../components/section-header/section-header";
import HorizontalTimeline from "../components/horizontal-timeline/horizontal-timeline";
import TestimontialSlider from "../components/testimontial-slider/testimontial-slider";
import BasicButton from "../components/buttons/basic-button/basic-button";

// markup
const AboutUsPage = (props: PageProps) => {
  return (
    <DefaultTemplate>
      <section className="container introduce-section">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 introduce-text-wrapper">
            <h2>{aboutUsSection.title}</h2>
            <p>{aboutUsSection.text}</p> 
          </div>
        </div>
      </section>
      <section className="container tech-stack-section">
        <div className="row">
          <div className="col-12 col-lg-5 tech-stack-text-wrapper">
            <h2>{techStackSection.title}</h2>
          </div>
          <div className="col-12 col-lg-6 tech-stack-pills-wrapper">
            {techStackSection.skills.map((s, i) => <span key={i}>{s}</span>) } 
          </div>
        </div>
      </section>

      <section className="container staff-section">
        <div className="row">
          <div className="col-12">
            <SectionHeader data={staffSection}></SectionHeader>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-4 staff-member-container">
            <div className="staff-member-avatar">
              <img src={staffSectionML.image} />
            </div>
            <h2>{staffSectionML.name} <br></br><small>{staffSectionML.Role}</small></h2>
            <p>{staffSectionML.description}</p>
            <ul>
              {staffSectionML.socials.map(s =>
                <li><a target="_blank" href={`${s.link}`}>{s.title}</a></li>)} 
            </ul>
          </div>
          <div className="col-12 col-lg-4 staff-member-container">
            <div className="staff-member-avatar">
              <img src={staffSectionPM.image} />
            </div>
            <h2>{staffSectionPM.name} <br></br><small>{staffSectionPM.Role}</small></h2>
            <p>{staffSectionPM.description}</p>
            <ul>
              {staffSectionPM.socials.map(s =>
                <li><a target="_blank" href={`${s.link}`}>{s.title}</a></li>)} 
            </ul>
          </div>
        </div>
      </section>

      
      <section className="container experience-section">
        <div className="row">
          <div className="col-12">
            <SectionHeader data={storySection}></SectionHeader>
          </div>
          <div className="col-12">
            <HorizontalTimeline data={storySection}></HorizontalTimeline>
          </div>
        </div>
      </section>
      <section className="container testimonial-section">
        <div className="row">
          <TestimontialSlider data={testimontialSection}></TestimontialSlider>
        </div>
      </section>
      <section className="container contact-reference-section">
        <div className="row">
          <div className="col-12">
            <p>{"\"" + contactSection.text + "\""}</p>
            <BasicButton data={contactSection.button}></BasicButton>
          </div>
        </div>
      </section>
    </DefaultTemplate>
  )
}

export default AboutUsPage